import { useEffect } from 'react'
import { any } from 'prop-types'

// modules
import Auth from '@modules/auth'
import Env from '@modules/env'
import Router from '@modules/router'
import Url from '@modules/url'

const IndexPage = ({ location }) => {
  useEffect(() => {
    Env.isClient(() => {
      Auth.getToken((err, token) => {
        const queryString = Url.parseSearch(location.search || {})
        const { encodedForwardUrl, session } = queryString || {}

        const decodedAuthForwadUrl = Auth.updateAuthForwardUrl(
          null,
          decodeURIComponent(encodedForwardUrl)
        )

        if (err || !token) {
          return Router.goToLoginWithSecuredForwardUrl(
            decodedAuthForwadUrl,
            session === 'closed' ? 'logout' : ''
          )
        }
        Router.go('/leads')
      })
    })
  }, [location])

  return null
}

IndexPage.propTypes = {
  location: any
}
IndexPage.defaultProps = {
  location: {}
}

export default IndexPage
